const publicRoutes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login'),
    meta: {
      onlyGuest: true,
    }
  },
]

export default publicRoutes