import {
  createApp
} from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { quillEditor, Quill } from 'vue3-quill'
import customQuillModule from './components/customQuillModule'
Quill.register('./components/customQuillModule', customQuillModule)
import i18n from './i18n'

// function loadLocaleMessages() {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

// const i18n = createI18n({
//   locale: 'en', // set locale
//   fallbackLocale: 'he', // set fallback locale
//   messages: loadLocaleMessages()
// })

import './css/main.css'

createApp(App).use(i18n).use(quillEditor).use(Toast).use(i18n).use(store).use(router).mount('#app')
