<template>
  <div class="relative flex flex-col wrapper-container overflow-x-hidden">
    <nav-bar v-if="user.id" />
    <aside-menu :menu="menu" v-if="user.id" />
    <router-view />
    <!-- <footer-bar v-if="user" /> -->
    <overlay v-show="isAsideLgActive" z-index="z-30" @overlay-click="overlayClick" />
  </div>
</template>

<script>
// @ is an alias to /src
import menu from "@/menu.js";
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
// import FooterBar from "@/components/FooterBar";
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: "Home",
  components: {
    // FooterBar,
    AsideMenu,
    NavBar,
  },
  computed: {
    user() {
      return this.$store.getters["User/user"];
    },
  },
  setup() {
    const store = useStore()

    const isAsideLgActive = computed(() => store.state.isAsideLgActive)

    const overlayClick = () => {
      store.dispatch('asideLgToggle', false)
    }

    return {
      isAsideLgActive,
      overlayClick,
      menu,
    };
  },
};
</script>
