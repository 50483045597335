const initialState = () => ({
  errors: {},
  message: {
    message: null,
    type: null
  }
})

const state = initialState()

const actions = {}

const getters = {
  get (state) { return state.message }
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_MESSAGE (state, message) {
    state.message = message
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
