import {
  mdiGenderMaleFemale,
} from '@mdi/js'

export default [
  [
    // {
    //     label: $t(Genders),
    //     subLabel: 'Submenus Example',
        // icon: mdiGenderMaleFemale,
    //     menu: [
    //       {
    //         to: {
    //           name: 'GenderList'
    //         },
    //         label: 'Gender list'
    //       }
    //     ]
    //   },
    {
      to: {
        name: 'GenderList'
      },
      // icon: mdiGenderMaleFemale,
      label: 'genders',
      updateMark: true
    },
    {
      to: {
        name: 'EventTypeList'
      },
      // icon: mdiGenderMaleFemale,
      label: 'event_types',
      updateMark: true
    },
    {
      to: {
        name: 'EventList'
      },
      // icon: mdiGenderMaleFemale,
      label: 'events',
      updateMark: true
    },
    {
      to: {
        name: 'PostList'
      },
      // icon: mdiGenderMaleFemale,
      label: 'posts',
      updateMark: true
    },
    {
      to: {
        name: 'CountryList'
      },
      // icon: mdiGenderMaleFemale,
      label: 'countries',
      updateMark: true
    },
    {
      to: {
        name: 'staticPages'
      },
      // icon: mdiGenderMaleFemale,
      label: 'static_pages',
      updateMark: true
    },
    {
      to: {
        name: 'UserList'
      },
      // icon: mdiGenderMaleFemale,
      label: 'Users',
      updateMark: true
    },
    {
      to: {
        name: 'NewChat'
      },
      // icon: mdiGenderMaleFemale,
      label: 'new_chat',
      updateMark: true
    },
    {
      to: {
        name: 'Chats'
      },
      // icon: mdiGenderMaleFemale,
      label: 'messages',
      updateMark: true
    },
    {
      to: {
        name: 'Mails'
      },
      // icon: mdiGenderMaleFemale,
      label: 'mails',
      updateMark: true
    },
    {
      to: '/settings',
      label: 'settings',
      //  
    },
    {
      to: '/banner',
      label: 'banner',
      //  
    },
    // {
    //   to: '/ui',
    //   label: 'UI',
    //   icon: mdiTelevisionGuide
    // },
    // {
    //   to: '/responsive',
    //   label: 'Responsive',
    //   icon: mdiResponsive
    // },
    // {
    //   to: '/profile',
    //   label: 'Profile',
    //   icon: mdiAccountCircle
    // },
    // {
    //   to: '/login',
    //   label: 'Login',
    //   icon: mdiLock
    // },
    // {
    //   label: 'Submenus',
    //   subLabel: 'Submenus Example',
    //   icon: mdiViewList,
    //   menu: [
    //     {
    //       label: 'Sub-item One'
    //     },
    //     {
    //       label: 'Sub-item Two'
    //     }
    //   ]
    // }
  ]
]
