const privateRoutes = [
    {
        path: '/tables',
        name: 'Table',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Tables'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/genders',
        name: 'GenderList',
        component: () => import(/* webpackChunkName: "login" */ '@/views/GenderList'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/event-types',
        name: 'EventTypeList',
        component: () => import(/* webpackChunkName: "login" */ '@/views/EventTypeList'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/countries',
        name: 'CountryList',
        component: () => import(/* webpackChunkName: "login" */ '@/views/CountryList'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cities/:id',
        name: 'CityList',
        component: () => import(/* webpackChunkName: "login" */ '@/views/CityList'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events',
        name: 'EventList',
        component: () => import(/* webpackChunkName: "login" */ '@/views/EventList'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/posts',
        name: 'PostList',
        component: () => import(/* webpackChunkName: "login" */ '@/views/PostList'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: 'UserList',
        component: () => import(/* webpackChunkName: "login" */ '@/views/UserList'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/static-pages',
        name: 'staticPages',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Blogs'),
        meta: {
            requiresAuth: true
        }
    },
    // {
    //     path: '/forms',
    //     name: 'form',
    //     component: () => import(/* webpackChunkName: "login" */ '@/views/Forms'),
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
    {
        path: '/blogs',
        name: 'Blogs',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Blogs'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Settings'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/banner',
        name: 'BannerList',
        component: () => import(/* webpackChunkName: "login" */ '@/views/BannerList'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/new-chat',
        name: 'NewChat',
        component: () => import(/* webpackChunkName: "login" */ '@/views/NewChat'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/mails',
        name: 'Mails',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Mails'),
        meta: {
            requiresAuth: true
        }
    },
    {
      path: '/chats',
      name: 'Chats',
      component: () => import(/* webpackChunkName: "login" */ '../views/Chats'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/chat/:id',
      name: 'UserChat',
      component: () => import(/* webpackChunkName: "login" */ '../views/Chats'),
      meta: {
        requiresAuth: true
      }
    },
]
export default privateRoutes
