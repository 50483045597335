import {
  createRouter,
  createWebHistory
} from "vue-router";
import publicRoutes from './public'
import privateRoutes from './private'
import store from '@/store'

const routes = publicRoutes.concat(privateRoutes)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    store.dispatch('User/getAuth')
    .then((user) => {
      if (user) {
        // this.$i18n.locale = store.getters['User/user_lang'];
        next()
      } else {
        next({
          name: 'Login'
        });
      }
    }).catch((error) => {
      store.commit('Message/SET_MESSAGE', {
        message: error.message,
        type: 'warning'
      })
      next({
        name: 'Login'
      })
    })
  } else if (to.matched.some(route => route.meta.onlyGuest)) {
    store.dispatch('User/setAuthFromLocal')
      .then(() => {
        if (store.getters['User/isLoggedin']) {
          next({
            name: 'GenderList'
          })
        } else {
          next()
        }
      })
  } else {
    if (store.getters['User/isFirstLoad']) {
      store.commit('User/SET_FIRST_LOAD_STATUS', false)
    }
    next()
  }
})
// /* Default title tag */
// const defaultDocumentTitle = 'Admin One Vue 3 Tailwind'
// router.afterEach(to => {
//   if (to.meta && to.meta.title) {
//     document.title = `${to.meta.title} — ${defaultDocumentTitle}`
//   } else {
//     document.title = defaultDocumentTitle
//   }
// })

export default router
